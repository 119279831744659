import { defineMessages } from 'react-intl';

export default defineMessages({
    heading: {
        id: 'send-message.heading',
        defaultMessage: 'Send us a message'
    },
    para1: {
        id: 'send-message.para1',
        defaultMessage: 'If you have a question about your claim, select a subject from the dropdown list below and provide a brief summary. All information you share will be added to your claim file. A customer service representative will respond to your message within '
    },
    two: {
        id: 'send-message.two',
        defaultMessage: 'two'
    },
    para11: {
        id: 'send-message.para11',
        defaultMessage: ' business days.'
    },
    para2: {
        id: 'send-message.para2',
        defaultMessage: 'To send us information about your claim, please go to '
    },
    para3: {
        id: 'send-message.para3',
        defaultMessage: '.'
    },
    uploadLink: {
        id: 'send-message.uploadLink',
        defaultMessage: 'submit document(s)'
    },
    Subject: {
        id: 'send-message.Subject',
        defaultMessage: 'Subject:'
    },
    Body: {
        id: 'send-message.Body',
        defaultMessage: 'Body:'
    },
    Send: {
        id: 'send-message.Send',
        defaultMessage: 'Send'
    },
    count: {
        id: 'send-message.count',
        defaultMessage: 'Character count :',
    },
    contactUsText: {
        id: 'send-message.contactUsText',
        defaultMessage: 'If you have an immediate request or any other inquiries, please '
    },
    contactUsLink: {
        id: 'send-message.contactUsLink',
        defaultMessage: 'contact us'
    },
    contactUsText2: {
        id: 'send-message.contactUsText2',
        defaultMessage: '. Have questions about our online services? '
    },
    faqLink: {
        id: 'send-message.faqLink',
        defaultMessage: 'See our FAQs'
    },
    contactUsText3: {
        id: 'send-message.contactUsText3',
        defaultMessage: '.'
    },
    ChooseOne: {
        id: 'send-message.Chooseone',
        defaultMessage: 'Choose one'
    },
    ClaimStatusInformation: {
        id: 'send-message.ClaimStatusInformation',
        defaultMessage: 'Claim status information'
    },
    Payments: {
        id: 'send-message.Payments',
        defaultMessage: 'Payments'
    },
    ReturnToWork: {
        id: 'send-message.ReturnToWork',
        defaultMessage: 'Getting back to work / staying at work'
    },
    Treatment: {
        id: 'send-message.Treatment',
        defaultMessage: 'Treatment / medication'
    },
    GeneralQuestion: {
        id: 'send-message.GeneralQuestion',
        defaultMessage: 'General question'
    },
    Appeals: {
        id: 'send-message.Appeals',
        defaultMessage: 'Appeals'
    },
    MSG41: {
        id: 'send-message.MSG41',
        defaultMessage: 'Please try again later. If you’re still having trouble with this page, call us at: 1-800-387-0750.'
    },
    MSG22: {
        id: 'send-message.MSG22',
        defaultMessage: 'Are you sure you want to leave this page?\nYou will lose information you entered.'
    },
    StayOnPage: {
        id: 'send-message.StayOnPage',
        defaultMessage: 'Stay on this page'
    },
    LeavePage: {
        id: 'send-message.LeavePage',
        defaultMessage: 'Leave this page'
    },
    MSG79: {
        id: 'send-message.MSG79',
        defaultMessage: 'Are you sure you want to continue?\nYou will lose information you entered'
    },
    Okay: {
        id: 'send-message.Okay',
        defaultMessage: 'Okay'
    },
    Cancel: {
        id: 'send-message.Cancel',
        defaultMessage: 'Cancel'
    },
});
