/* eslint-disable prefer-destructuring */
/* eslint-disable radix */
/* eslint-disable use-isnan */
/* eslint-disable no-plusplus */
/* eslint-disable no-unused-vars */
/* eslint-disable no-param-reassign */
/* eslint-disable prefer-promise-reject-errors */
/* eslint-disable no-loop-func */
/* eslint-disable no-restricted-globals */
/* eslint-disable no-mixed-operators */
/* eslint-disable valid-jsdoc */
import { ModalNextProvider } from '@jutro/components';
// import { publish } from '@jutro/events';
import React from 'react';
// eslint-disable-next-line import/no-unresolved
import appConfig from 'app-config';
import RemoteConfiguration from '../key-configuration/configuration.json5';
import LocalConfiguration from '../key-configuration/localconfiguration.json5';

// ospcchanges customization to load localhost configuration if running on localhost
let Configuration;
if (appConfig.env.DEPLOYMENT_HOSTNAME === 'localhost') {
    Configuration = LocalConfiguration;
} else {
    Configuration = RemoteConfiguration;
}

// guidewire authentation
export default class UtilService {
    // function to check localhost testing environment
    static isLocalhostTest() {
        if (appConfig.env.DEPLOYMENT_HOSTNAME === 'localhost') {
            return true;
        }
        return false;
    }

    /**
   *
   * @param {any} value
   * @returns true
   * function to check given value is undefined , null, NaN, blank
   */
    static isEmptyValues(value) {
        if (value === '{' || value === '}' || value === '{}') {
            value = null;
        }
        // adding comments
        // eslint-disable-next-line use-isnan
        return (
            value === undefined
            || value === null
            || value === NaN
            || (typeof value === 'object' && Object.keys(value).length === 0)
            || (typeof value === 'string' && value.trim().length === 0)
        );
    }

    /**
   *
   * @param {string} value
   * @returns false
   * function to check given date is valid or not
   * when date is invalid it return true otherwise false.
   */
    static isValidDate(value) {
        const date = new Date(value);
        return !(date instanceof Date && !isNaN(date.valueOf()));
    }

    /**
   *
   * @param {string} key
   * @param {string} lang
   * @returns link
   * return footer link on the basis of key and language parameters.
   */
    static footerLink(key, lang) {
        if (lang === 'fr') {
            return Configuration.contentFrench[key];
        }
        return Configuration.contentEnglish[key];
    }

    /**
   *
   * @param {string} key
   * @param {string} lang
   * @returns link
   * return profile link on the basis of key and language parameters.
   */
    static profileLink(lang) {
        if (lang === 'fr') {
            return this.HandleAmpercend(process.env.REACT_APP_PROFILE_URL_FR);
        }
        return this.HandleAmpercend(process.env.REACT_APP_PROFILE_URL_EN);
    }

    /**
   *
   * @param {string} key
   * @param {string} lang
   * @returns link
   * return business link on the basis of key and language parameters.
   */
    static businessLink(lang) {
        if (lang === 'fr') {
            return this.HandleAmpercend(process.env.REACT_APP_BUSINESS_URL_FR);
        }
        return this.HandleAmpercend(process.env.REACT_APP_BUSINESS_URL_EN);
    }

    static getMyClaimsLink() {
        return Configuration.myClaimsUrl;
    }

    static getTravelLink(lang) {
        if (lang === 'fr') {
            return this.HandleAmpercend(Configuration.travel.fr);
        }
        return this.HandleAmpercend(Configuration.travel.en);
    }

    /**
   *
   * @param {string} key
   * @param {string} lang
   * @returns link
   * return unified logout link on the basis of key and language parameters.
   */
    static unifiedLogout(lang) {
        if (lang === 'fr') {
            return this.HandleAmpercend(process.env.REACT_APP_UNIFIED_LOGOUT_URL_FR);
        }
        return this.HandleAmpercend(process.env.REACT_APP_UNIFIED_LOGOUT_URL_EN);
    }

    /**
   *
   * @param {string} key
   * @param {string} lang
   * @returns link
   * return ill link on the basis of key and language parameters.
   */
    static illOrInjured(lang) {
        if (lang === 'fr') {
            return this.HandleAmpercend(Configuration.illOrInjured.fr);
        }
        return this.HandleAmpercend(Configuration.illOrInjured.en);
    }

    /**
   *
   * @param {string} key
   * @param {string} lang
   * @returns link
   * return file a claim link on the basis of key and language parameters.
   */
    static faq(lang) {
        if (lang === 'fr') {
            return Configuration.faq.fr;
        }
        return Configuration.faq.en;
    }

    /**
   *
   * @param {string} key
   * @param {string} lang
   * @returns link
   * return file a claim link on the basis of key and language parameters.
   */
    static doaFormLink(lang) {
        if (lang === 'fr') {
            return Configuration.doaFormLink.fr;
        }
        return Configuration.doaFormLink.en;
    }

    /**
   *
   * @param {string} key
   * @param {string} lang
   * @returns link
   * return unified logout session expired link on the basis of key and language parameters.
   */
    static unifiedLogoutSessionExpired(lang) {
        if (lang === 'fr') {
            return this.HandleAmpercend(Configuration.unifiedLogoutSessionExpired.fr);
        }
        return this.HandleAmpercend(Configuration.unifiedLogoutSessionExpired.en);
    }

    static directdepositSurveyLink(lang) {
        if (lang === 'fr') {
            return this.HandleAmpercend(Configuration.surveyLinkDirectDeposit.fr);
        }
        return this.HandleAmpercend(Configuration.surveyLinkDirectDeposit.en);
    }

    /**
   *
   * @param {string} key
   * @param {string} lang
   * @returns link
   * return online services link link on the basis of key and language parameters.
   */
    static onlineServices(lang) {
        if (lang === 'fr') {
            return this.HandleAmpercend(process.env.REACT_APP_ONLINE_SERVICE_URL_FR);
        }
        return this.HandleAmpercend(process.env.REACT_APP_ONLINE_SERVICE_URL_EN);
    }

    static createProfileUrl(lang) {
        if (lang === 'fr') {
            return this.HandleAmpercend(process.env.REACT_APP_LFB_CREATE_PRO_URL_FR);
        }
        return this.HandleAmpercend(process.env.REACT_APP_LFB_CREATE_PRO_URL_EN);
    }

    /**
   *
   * @param {string} key
   * @param {string} lang
   * @returns link
   * return footer link on the basis of key and language parameters.
   */
    static fetchAriaLabel(key, lang) {
        if (lang === 'fr') {
            return Configuration.ariaLabelFrench[key];
        }
        return Configuration.ariaLabelEnglish[key];
    }

    /**
   * @param {string} URL
   * @returns link
   * replace {AND} with &
   */
    static HandleAmpercend(value) {
        return value.toString().replace(/{AND}/g, '&');
    }

    // Appending aria-labels for OOTB  Data-table pagination
    static setPaginationAriaLabels(tableID) {
        const pagination = [];
        const links = document.querySelectorAll(
            `${tableID} .jut__Pagination__pageButtonsWrapper .jut__Pagination__paginationMenuItem`
        );
        const buttons = document.querySelectorAll(
            `${tableID} .jut__Pagination__pageButtonsWrapper .jut__Pagination__paginationNavButton`
        );
        const collapsedButtons = document.querySelectorAll(
            `${tableID} .jut__Pagination__pageButtonsWrapper .jut__DropdownMenu__dropdownMenu button`
        );

        links.forEach((link) => {
            pagination.push({ text: +link.innerText, target: link });
        });
        buttons.forEach((button) => {
            pagination.push({ text: +button.innerText, target: button });
        });

        // Page buttons
        const pageButtons = pagination
            .filter((pageLink) => {
                return pageLink.target.type === 'button';
            })
            .sort((a, b) => a.text - b.text);

        const buttonCount = pageButtons.length;
        let prevPageButton = 0;

        pageButtons.forEach((pb, index) => {
            const button = pb.target;
            const bIndex = index + 1;
            button.setAttribute(
                'aria-label',
                `Button ${bIndex} of ${buttonCount}, Page ${pb.text}`
            );

            // Page dropdown Links
            if (pb.text - prevPageButton > 1) {
                const diff = pb.text - prevPageButton - 1;

                let linkCtr = prevPageButton + 1;
                do {
                    const a = () => {
                        const link = pagination.find((page) => {
                            return page.target.type !== 'button' && page.text === linkCtr;
                        });

                        const text = linkCtr - prevPageButton;
                        link.target.setAttribute(
                            'aria-label',
                            `Dropdown item ${text} of ${diff}, Page ${link.text}`
                        );
                        return true;
                    };
                    a();
                    linkCtr += 1;
                } while (linkCtr < pb.text);
            }
            prevPageButton = pb.text;
        });

        // Aria-Labelling Expandable buttons
        collapsedButtons.forEach((link) => {
            link.setAttribute('aria-label', 'Expand for more options');
        });
    }

    /**
   *
   * @param {string} key
   * @param {string} lang
   * @returns link
   * return claim form link on the basis of key and language parameters.
   */
    static claimForm(lang) {
        if (lang === 'fr') {
            return Configuration.claimForm.fr;
        }
        return Configuration.claimForm.en;
    }

    static claimFormSubmit(lang) {
        if (lang === 'fr') {
            return Configuration.claimFormSubmit.fr;
        }
        return Configuration.claimFormSubmit.en;
    }

    static claimFormUpdate(lang) {
        if (lang === 'fr') {
            return Configuration.claimFormUpdate.fr;
        }
        return Configuration.claimFormUpdate.en;
    }

    static claimFormRequest(lang) {
        if (lang === 'fr') {
            return Configuration.claimFormRequest.fr;
        }
        return Configuration.claimFormRequest.en;
    }

    static hearingLossClaim(lang) {
        if (lang === 'fr') {
            return Configuration.hearingLossClaim.fr;
        }
        return Configuration.hearingLossClaim.en;
    }

    /*
   * @param {string} date
   * @returns date
   * get content on the basis of key
   */
  static customDateFormatMailView = (date, lang) => {
      try {
          const day = date.toString().split(' ')[0];
          const p = new Intl.DateTimeFormat(lang, {
              day: 'numeric',
              month: 'short',
              year: 'numeric',
              hour: '2-digit',
              minute: '2-digit',
              hour12: true,
          })
              .formatToParts(new Date(date))
              .reduce((acc, part) => {
                  acc[part.type] = part.value;
                  return acc;
              }, {});
          let returnDate = `${day} ${p.month} ${p.year}`;
          if (lang === 'fr') {
              if (p.month.toLowerCase() === 'juin') {
                  returnDate = `${day} JUN ${
                      p.year
                  }`;
              } else if (p.month.toLowerCase() === 'juil.') {
                  returnDate = `${day} JUL ${
                      p.year
                  }`;
              } else {
                  returnDate = `${day} ${p.month.substring(0, 3).toUpperCase()} ${
                      p.year
                  }`;
              }
          }
          return returnDate;
      } catch (error) {
          // eslint-disable-next-line no-console
          console.log(error);
          return '';
      }
  };

    /*
     * @param {string} date
     * @returns date
     * get content on the basis of key
     */
    static customDateFormat = (date, lang) => {
        try {
            let day;
            if(date.includes('T')) {
                day = date.toString().split('T')[0];
                day = day.split('-')[2];
            } else {
                day = date.substring(0,2);
            }
            const p = new Intl.DateTimeFormat(lang, {
                day: 'numeric',
                month: 'short',
                year: 'numeric',
                hour: '2-digit',
                minute: '2-digit',
                hour12: true,
            })
                .formatToParts(new Date(date))
                .reduce((acc, part) => {
                    acc[part.type] = part.value;
                    return acc;
                }, {});
            let returnDate = `${day} ${p.month} ${p.year}`;
            if (lang === 'fr') {
                if (p.month.toLowerCase() === 'juin') {
                    returnDate = `${day} JUN ${
                        p.year
                    }`;
                } else if (p.month.toLowerCase() === 'juil.') {
                    returnDate = `${day} JUL ${
                        p.year
                    }`;
                } else {
                    returnDate = `${day} ${p.month.substring(0, 3).toUpperCase()} ${
                        p.year
                    }`;
                }
            }
            return returnDate;
        } catch (error) {
            // eslint-disable-next-line no-console
            console.log(error);
            return '';
        }
    };

    /*
     * @param {string} lang
     * redirect if user logged
     */
    // ospcchange need to check whether need to implement below method for ospc
    static checkIfUserLoggedIn = (lang) => {
        // if (document.cookie.indexOf('Current-User=') === -1) {
        //     window.location.href = this.unifiedLogout(lang);
        // }
    };

    /*
     * @param {object} authority
     * redirect if user role is changed
     */
    static checkUserRole = (authority) => {
        const matchRole = new Promise((reject, resolve) => {
            const roles = document
                .getElementsByTagName('html')[0]
                .getAttribute('accesToken');
            if (this.previousRoleArr !== roles) {
                resolve(true);
            } else {
                reject(false);
            }
        });
        return matchRole;
    };

    /*
     * awaiting modal popup using for various purposes
     */
    static navigateAway = async (MSG, cancel, ok) => {
        const displayMessage = (
            <div style={{ textAlign: '-webkit-center' }}>
                <h2 className="diModalHeader">{`${MSG.split('?')[0]}?`}</h2>
                <p className="diModalContent">{`${MSG.split('?')[1]}`}</p>
            </div>
        );
        const results = await ModalNextProvider.showConfirm({
            status: 'warning',
            icon: 'mi-error-outline',
            confirmText: 'Confirmation',
            message: displayMessage,
            cancelButtonText: ok,
            confirmButtonText: <div className=''>{`${cancel}`}</div>
            
        });
        return new Promise((resolve, reject) => {
            if (results !== 'confirm') {
                sessionStorage.removeItem('documentAdded');
                resolve(true);
            }
            // eslint-disable-next-line prefer-promise-reject-errors
            reject(false);
        });
    };

    /*
     *
     * getMessageType for message
     */
    static getMessageType = () => {
        return Configuration.MessageType;
    };

    /*
     * @param {string} originalMessage
     * @param {string} params
     * @param {string} specialChars
     * return originalMessage with replacement of special characters.
     */
    static encodeMessage = (originalMessage, params, specialChars) => {
        let encodedMessage = originalMessage;
        const encodedList = params.split(',');
        // eslint-disable-next-line no-restricted-syntax
        for (const encode of encodedList) {
            encodedMessage = originalMessage.replace(
                new RegExp(`\\b${encode.split(':')[0]}\\b`, 'g'),
                encode.split(':')[1]
            );
            // eslint-disable-next-line no-param-reassign
            originalMessage = encodedMessage;
        }
        const specialCharList = specialChars.split(';');
        // eslint-disable-next-line no-restricted-syntax
        for (const specChar of specialCharList) {
            encodedMessage = originalMessage.replace(
                new RegExp(specChar.split(':')[0], 'g'),
                specChar.split(':')[1]
            );
            originalMessage = encodedMessage;
        }
        return originalMessage;
    };

    /*
     * @param {array} roles
     * return looged in user role
     */
    static getUserRole = (role) => {
        let roles = document
            .getElementsByTagName('html')[0]
            .getAttribute('accesToken');
        const authorities = [];
        roles = roles.split(' ');
        roles.forEach((data) => {
            const authData = data.split('.');
            authorities.push(authData[authData.length - 1].toLowerCase());
        });
        if (
            authorities.indexOf('admin_role') !== -1
            && authorities.indexOf('user_role') !== -1
        ) {
            return 'HYBRID';
        }
        if (
            authorities.indexOf('admin_role') !== -1
            && authorities.indexOf('user_role') === -1
        ) {
            return 'ADMIN';
        }
        if (
            authorities.indexOf('admin_role') === -1
            && authorities.indexOf('user_role') !== -1
        ) {
            return 'USER';
        }
        return '';
    };

    static submittedDocuments = [];

    static previousRole = '';

    static previousRoleArr = '';

    static setMessageThread = (data) => {
        sessionStorage.setItem('messageThread', JSON.stringify(data));
    };

    static getMessageThread = () => {
        if (sessionStorage.messageThread) {
            return JSON.parse(sessionStorage.getItem('messageThread'));
        }

        return [];
    };

    static deleteAllCookies = () => {
        const cookies = document.cookie.split(';');
        for (let i = 0; i < cookies.length; i++) {
            const cookie = cookies[i];
            const eqPos = cookie.indexOf('=');
            const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
            document.cookie = `${name}=;expires=Thu, 01 Jan 1970 00:00:00 GMT`;
        }
    };

    /**
     *
     * @param {string} key
     * @param {string} lang
     * @returns link
     * return business link on the basis of key and language parameters and error code.
     */
    static businessLinkError(lang) {
        if (lang === 'fr') {
            return this.HandleAmpercend(Configuration.businessLinkError.fr);
        }
        return this.HandleAmpercend(Configuration.businessLinkError.en);
    }

    /**
     *
     * @param {string} key
     * @param {string} lang
     * @returns link
     * return business link on the basis of key and language parameters and error code.
     */
    static businessLinkGeneralError() {
        return this.HandleAmpercend(Configuration.businessLinkGeneralError);
    }

    // ospcchange call a method to set tombstone data for refreshing the tombstone component
    // static tombStoneData = null;
    static setTombStoneData = () => {
    };

    // return empty string if claim number is not available in url
    static fetchClaimNumber() {
        const url = (window.location.pathname).split('/');
        return url[url.length - 1];
    }

    static languageChanged = false;

    static fetchInsightKey() {
        // return Configuration.ApplicationInsight;
        console.log(`app insight instrument key: ${process.env.REACT_APP_APPLICATION_INSIGHT}`);
        return process.env.REACT_APP_APPLICATION_INSIGHT;
    }

    static generateRandomId() {
        return `_${Math.random().toString(36).substr(2, 9)}`;
    }

    /**
     * @param {string} lang
     * @returns link
     */
    static getConfirmationSurveyLink(lang) {
        if (lang === 'fr') {
            return Configuration.surveyLinkClaimConfirmation.fr;
        }
        return Configuration.surveyLinkClaimConfirmation.en;
    }

    static getChromeLink(lang) {
        if (lang === 'fr') {
            return Configuration.chromeLink.fr;
        }
        return Configuration.chromeLink.en;
    }

    static getEdgeLink(lang) {
        if (lang === 'fr') {
            return Configuration.edgeLink.fr;
        }
        return Configuration.edgeLink.en;
    }
}
